.home-screen {
  display: grid;
  grid-template-columns: 50% 50%;
}

.item .title {
  padding: 1vmax;
  font-size: 2vmax;
  background-color: rgba(0, 0, 0, 0.25);
}
