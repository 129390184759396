.App {
  text-align: center;
}

.App-logo {
  position: fixed;
  top: 0;
  width: 50%;

  pointer-events: none;
}

.App {
  background: url(/splash.png) #282c34;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-shadow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-shadow > * {
  background-color: rgba(40, 44, 52, 0.5);
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

#root, .app {
  width: 100%;
  height: 100%;
  position: fixed;
}
