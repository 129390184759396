.dashboard-view {
  padding: 10px;
  border-radius: 10px;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 5px;
  background-color: #17191c;
}

.app {
  overflow: auto;
  background-color: #0b0c0e;
}

.content {
  color: #fff
}