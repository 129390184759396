table {
    width: 100%;
    color: #fff;
}

.list-table td { 
    cursor: pointer;
}

.list-table tbody tr:hover {
    background-color: rgb(0, 70, 150);
}

thead tr {background:#444}
tr:nth-child(even) {background: #444}
tr:nth-child(odd) {background: #333}