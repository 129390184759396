.navbar-brand a, .navbar-brand a:hover, #accountControls {
  color: #fff;
}

#personIcon {
  margin-right: 5px;
  vertical-align: middle;
}

#personName {
  vertical-align: middle;
}

.dropdown-toggle {
  vertical-align: middle;
}

#accountControls::after {
  display: none;
  width: 0;  
}
