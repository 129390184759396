.receipt {
    max-width: 800px;
    margin: 0 auto;
    background-color: #333;
    padding: 10px;
}
.namePrice {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.map-preview {
    max-width: 100%;
    padding: 10px 0px 10px 0px;
}

td { 
    cursor: default;
    padding: 5px;
    max-width: 100%;
}

small {
    text-align: center;
    display: block;
    opacity: 0.8;
}

.rawData {
    cursor: pointer;
}